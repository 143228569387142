@font-face {
    font-family: 'TWK Lausanne';
    font-style: normal;
    font-weight: 300;
    src: url('../assets/fonts/TWK Lausanne/TWKLausanne-300.woff2') format('woff2'), /* Super Modern Browsers */ url('../assets/fonts/TWK Lausanne/TWKLausanne-300.woff') format('woff'), /* Modern Browsers */ url('../assets/fonts/TWK Lausanne/TWKLausanne-300.ttf') format('truetype') /* Safari, Android, iOS */
}

@font-face {
    font-family: 'TWK Lausanne';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/TWK Lausanne/TWKLausanne-400.woff2') format('woff2'), /* Super Modern Browsers */ url('../assets/fonts/TWK Lausanne/TWKLausanne-400.woff') format('woff'), /* Modern Browsers */ url('../assets/fonts/TWK Lausanne/TWKLausanne-400.ttf') format('truetype') /* Safari, Android, iOS */
}

@font-face {
    font-family: 'TWK Lausanne';
    font-style: normal;
    font-weight: 500;
    src: url('../assets/fonts/TWK Lausanne/TWKLausanne-500.woff2') format('woff2'), /* Super Modern Browsers */ url('../assets/fonts/TWK Lausanne/TWKLausanne-500.woff') format('woff'), /* Modern Browsers */ url('../assets/fonts/TWK Lausanne/TWKLausanne-500.ttf') format('truetype') /* Safari, Android, iOS */
}

@font-face {
    font-family: 'TWK Lausanne';
    font-style: normal;
    font-weight: 600;
    src: url('../assets/fonts/TWK Lausanne/TWKLausanne-600.woff2') format('woff2'), /* Super Modern Browsers */ url('../assets/fonts/TWK Lausanne/TWKLausanne-600.woff') format('woff'), /* Modern Browsers */ url('../assets/fonts/TWK Lausanne/TWKLausanne-600.ttf') format('truetype') /* Safari, Android, iOS */
}

@font-face {
    font-family: 'TWK Lausanne';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/TWK Lausanne/TWKLausanne-700.woff2') format('woff2'), /* Super Modern Browsers */ url('../assets/fonts/TWK Lausanne/TWKLausanne-700.woff') format('woff'), /* Modern Browsers */ url('../assets/fonts/TWK Lausanne/TWKLausanne-700.ttf') format('truetype') /* Safari, Android, iOS */
}