@import "font-TWK-Lausanne.css";
@import "font-utopia.css";

:root {
    --background: #F6EDFF;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior-y: none;
    
    /* In iOS it's better to define the background
    element rather then app root element */
    background-color: var(--background);
}

#front-chat-container {
    transform: translateY(-90px);
}

#front-chat-container .fc-3xgLq {
    border-radius: 50% !important;
    background-color: transparent !important;
    background-image: url("../assets/images/daniel.png") !important;
    background-size: contain !important;
    background-position: center !important;
    width: 60px !important;
    height: 60px !important;
}

#front-chat-container .fc-3MlG7 {
    bottom: 0 !important;
    right: 120px !important;
}

.fc-1a_Zr,
.fc-ujDE8 {
    background-image: url("../assets/images/daniel.png") !important;
}

#front-chat-container .fc-ujDE8 {
    background-size: cover !important;
    border-radius: 50% !important;
}

.fc-3xgLq svg {
    display: none;
}

@media screen and (max-width: 899px) {
    #front-chat-container {
        display: none !important;
    }
}
